define(['app', '$console'], function(app, $console) {

  

  let userGeneratedContent = function() {
    let SELF = this;

    let _init = function(element) {
      SELF.element = element;
      let stringtemplatePath = element.dataset.st ? `stringtemplatepath=${element.dataset.st}` : '';
      let numThumbs = element.dataset.thumbs ? `maxThumbnails=${element.dataset.thumbs}` : '';
      let ugcEndpoint = `/${element.dataset.product}.ugc`;

      if ((stringtemplatePath !== '') && (numThumbs !== '')) {
        ugcEndpoint = `/${element.dataset.product}.ugc?${stringtemplatePath}&${numThumbs}`;
      }
      if ((stringtemplatePath === '') && (numThumbs !== '')) {
        ugcEndpoint = `/${element.dataset.product}.ugc?${numThumbs}`;
      }
      if ((stringtemplatePath !== '') && (numThumbs === '')) {
        ugcEndpoint = `/${element.dataset.product}.ugc?${stringtemplatePath}`;
      }

      // For Defer loading the UGC
      app.ajax.get({
        url: ugcEndpoint,
        success: _successCallback,
        error: function() {
          $console.debug('Cannot receive ugc:', ugcEndpoint);
        }
      });
    };

    let _successCallback = function(result) {
      SELF.element.innerHTML = result;
      require(['ugcPopup']);
    };

    SELF.init = _init;
  };

  return userGeneratedContent;
});
